import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const saveEnfantsAPI = createAsyncThunk(
  "enfant/saveEnfant",
  async (enfant) => {
    const response = await clientAPI.post("/api/enfant/save", enfant);
    return response.data;
  }
);

export const getListEnfantsAPI = createAsyncThunk(
  "enfant/getListEnfant",
  async (idConjoint) => {
    const response = await clientAPI.get("/api/enfant/get/" + idConjoint);
    return response.data;
  }
);

export const getListEnfantsByCompteAPI = createAsyncThunk(
  "enfant/getListEnfant",
  async (idCompte) => {
    const response = await clientAPI.get("/api/enfant/bycompte/" + idCompte);
    return response.data;
  }
);

export const countEnfantsByCompteAPI = createAsyncThunk(
  "enfant/countByCompte",
  async (idCompte) => {
    const response = await clientAPI.get("/api/enfant/count/" + idCompte);
    return response.data;
  }
);

export const getEnfantProfileImageAPI = createAsyncThunk(
  "enfant/getProfileImage",
  async (idEnfant) => {
    const response = await axios.get(
      SERVER_URL + "/api/fichier_enfant/profile/" + idEnfant,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const getEnfantFileAPI = createAsyncThunk(
  "enfant/getEnfantFile",
  async (data) => {
    const response = await axios.get(
      SERVER_URL + `/api/fichier_enfant/get/${data.id}/${data.typeFile}`,
      {
        responseType: "blob",
        headers: getAuthHeader(),
      }
    );
    return response.data;
  }
);

export const saveEnfantFichierAPI = createAsyncThunk(
  "enfant/savefichier",
  async (data) => {
    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    let token = localStorage.getItem("token");

    const response = axios.post(
      SERVER_URL +
        `/api/fichier_enfant/save/${data.idEnfant}/${data.idFichier}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        Accept: "application/json",
      }
    );
    return (await response).data;
  }
);

const EnfantSlice = createSlice({
  name: "enfant",
  initialState: {
    listEnfants: [],
    enfantToEdit: {},
    startEditing: false,
    saveEnfantStatus: null,
    savedEnfant: null,
    getListEnfantsStatus: null,
    countEnfants: 0,
  },
  reducers: {
    editEnfant: (state, action) => {
      state.enfantToEdit = action.payload;
    },
    resetEditedEnfant: (state, action) => {
      state.enfantToEdit = action.payload;
    },
    setStartEditing: (state, action) => {
      state.startEditing = action.payload;
    },
  },
  extraReducers: {
    // -------------- saveConjointsAPI ---------------
    [saveEnfantsAPI.pending]: (state, action) => {
      state.saveEnfantStatus = "pending";
      state.savedEnfant = null;
    },
    [saveEnfantsAPI.fulfilled]: (state, action) => {
      state.saveEnfantStatus = "fulfilled";
      state.savedEnfant = action.payload;
    },
    [saveEnfantsAPI.rejected]: (state, action) => {
      state.saveEnfantStatus = "rejected";
    },

    // --------- getListEnfantsAPI --------
    // [getListEnfantsAPI.pending]: (state, action) => {
    //   state.getListEnfantsStatus = "pending";
    // },
    // [getListEnfantsAPI.fulfilled]: (state, action) => {
    //   state.getListEnfantsStatus = "fulfilled";
    //   state.listEnfants = action.payload;
    // },
    // [getListEnfantsAPI.rejected]: (state, action) => {
    //   state.getListEnfantsStatus = "rejected";
    // },

    [getListEnfantsByCompteAPI.pending]: (state, action) => {
      state.getListEnfantsStatus = "pending";
    },
    [getListEnfantsByCompteAPI.fulfilled]: (state, action) => {
      state.getListEnfantsStatus = "fulfilled";
      state.listEnfants = action.payload;
    },
    [getListEnfantsByCompteAPI.rejected]: (state, action) => {
      state.getListEnfantsStatus = "rejected";
    },

    // --------- countEnfantsByCompteAPI --------
    [countEnfantsByCompteAPI.pending]: (state, action) => {
      state.countEnfants = 0;
    },
    [countEnfantsByCompteAPI.fulfilled]: (state, action) => {
      state.countEnfants = action.payload;
    },
    [countEnfantsByCompteAPI.rejected]: (state, action) => {
      state.countEnfants = null;
    },
  },
});

export const getListEnfants = (state) => state.enfant.listEnfants;
export const saveEnfantStatusSelector = (state) =>
  state.enfant.saveEnfantStatus;

export const savedEnfantSelector = (state) => state.enfant.savedEnfant;
export const getCountEnfantSelector = (state) => state.enfant.countEnfants;

export const { editEnfant, resetEditedEnfant, setStartEditing } =
  EnfantSlice.actions;

export default EnfantSlice.reducer;
