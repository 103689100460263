import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import fileDownload from "js-file-download";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";

export const saveFichierAPI = createAsyncThunk(
  "dossier/fichier",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("fileMeta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/dossier/save`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);

// export const getListTypeFichiersAPI = createAsyncThunk(
//   "dossier/getListTypeFichier",
//   async () => {
//     const response = await clientAPI.get("/api/type_fichier/list/");
//     return response.data;
//   }
// );

export const getDossierAdherentAPI = createAsyncThunk(
  "dossier/getDossierAdherent",
  async (idCompte) => {
    const response = await clientAPI.get("/api/dossier/by_compte/" + idCompte);
    return response.data;
  }
);

export const getFileByAdherentAndType = createAsyncThunk(
  "adherent/getFileByAdherentAndType",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
          `/api/fichier/get/${data.id_compte}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);

export const deleteFichierAPI = createAsyncThunk(
  "dossier/deleteFichierAPI",
  async (id) => {
    const response = await clientAPI.delete("/api/fichier/delete/" + id);
    return response.data;
  }
);

const DossierNumericSlice = createSlice({
  name: "dossier",
  initialState: {
    // typeFichiers: [],
    listFichiers: [],
    listFichierStatus: null,
    saveFichierStatus: null,
  },
  reducers: {
    resetDossierNumerique: (state, action) => {
      state.listFichiers = [];
      state.listFichierStatus = null;
      state.saveFichierStatus = null;
    },
  },
  extraReducers: {
    // -------------- saveFichierAPI ---------------
    [saveFichierAPI.pending]: (state, action) => {
      state.saveFichierStatus = "pending";
    },
    [saveFichierAPI.fulfilled]: (state, action) => {
      state.saveFichierStatus = "fulfilled";
    },
    [saveFichierAPI.rejected]: (state, action) => {
      state.saveFichierStatus = "rejected";
    },

    // -------------- getDossierAdherentAPI ---------------
    [getDossierAdherentAPI.pending]: (state, action) => {
      state.listFichierStatus = "pending";
    },
    [getDossierAdherentAPI.fulfilled]: (state, action) => {
      state.listFichierStatus = "fulfilled";
      state.listFichiers = action.payload;
      console.log("liste fichier :::: ",action.payload)
    },
    [getDossierAdherentAPI.rejected]: (state, action) => {
      state.listFichierStatus = "rejected";
    },
  },
});

export const saveFileStatusSelector = (state) =>
  state.dossier.saveFichierStatus;

export const { resetDossierNumerique } = DossierNumericSlice.actions;

export default DossierNumericSlice.reducer;
