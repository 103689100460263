import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";





export const listBrancheAPI = createAsyncThunk(
  "bourse/branche",
  async (data) => {
    const url = `/api/bourse/getBranche`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listAnneeAPI = createAsyncThunk(
  "bourse/annee",
  async (data) => {
    const url = `/api/bourse/getAnnee`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const listDemandeAPI = createAsyncThunk(
  "bourse/demande",
  async (data) => {
    const url = `/api/bourse/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const listTypeFichierAPI = createAsyncThunk(
  "bourse/typeFichier",
  async (data) => {
    const url = `/api/bourse/getTypeFichier`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const saveDemandeBourseAPI = createAsyncThunk(
  "bourse/bourseSaveDemande",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/bourse/bourseSaveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);
export const attribueeBourseAPI = createAsyncThunk(
  "bourse/bourseAttribuée",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/bourse/attribuee/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listBourseFilterAPI = createAsyncThunk(
  "bourse/bourseFilter",
  async (data) => {
    console.log('le data est :: ',data)
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/bourse/bourseFilter",data);
    return response.data;
  }
);


const Bourse = createSlice({
  name: "bourse",
  initialState: {
    listBranche: [],
    listAnnee: [],
    listTypeFichier: [],
    listDemande: [],
    savedDemande : {},
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listBrancheAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listBranche = action.payload;
      })
      .addCase(listAnneeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listAnnee = action.payload;
      })
      .addCase(listTypeFichierAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listTypeFichier = action.payload;
      })
      .addCase(listDemandeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('liste des demandes est :: ',action.payload )
      })
      .addCase(listBourseFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listDemande = action.payload;
        console.log('liste des demandes est :: ',action.payload )
      })


  },
});


export const {} = Bourse.actions;

export default Bourse.reducer;
