import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader } from "src/jwt/config";
import { SERVER_URL } from "src/serverConfig";
import {
  checkPprAdherentAPI,
  saveCompteAPI,
  savePersoAPI,
  saveProDataAPI,
} from "./AdherentSlice";
import { clientAPI } from "./axiosAnstance";

export const getAdherentCompteUpdateAPI = createAsyncThunk(
  "adherentUpdate/getCompte",
  async (idCompte) => {
    const response = await clientAPI.get("/api/compte/get/" + idCompte);
    return response.data;
  }
);

export const getAdherentPersoUpdateAPI = createAsyncThunk(
  "adherentUpdate/getPerso",
  async (idCompte) => {
    const response = await clientAPI.get("/api/adherent/by_compte/" + idCompte);
    return response.data;
  }
);

export const getAdherentProUpdateAPI = createAsyncThunk(
  "adherentUpdate/getPro",
  async (idCompte) => {
    const response = await clientAPI.get("/api/info_pro/by_compte/" + idCompte);
    return response.data;
  }
);

export const getAdherentConjointsUpdateAPI = createAsyncThunk(
  "adherentUpdate/getConjoints",
  async (compte) => {
    const response = await clientAPI.get("/api/compte/info/" + compte);
    return response.data;
  }
);

export const getAdherentEnfantsUpdateAPI = createAsyncThunk(
  "adherentUpdate/getEnfants",
  async (compte) => {
    const response = await clientAPI.get("/api/compte/info/" + compte);
    return response.data;
  }
);

export const getAdherentDossierUpdateAPI = createAsyncThunk(
  "adherentUpdate/getDossier",
  async (compte) => {
    const response = await clientAPI.get("/api/compte/info/" + compte);
    return response.data;
  }
);

export const getProfileImageAPI = createAsyncThunk(
  "adherentUpdate/getProfileImage",
  async (idAdherent) => {
    const response = await axios.get(
      SERVER_URL + "/api/fichier/profile/" + idAdherent,
      {
        headers: getAuthHeader(),
        responseType: "blob",
      }
    );
    return response.data;
  }
);

const AdherentUpdateSlice = createSlice({
  name: "adherentUpdate",
  initialState: {
    infoCompte: {},
    compteStatus: null,
    infoPerso: {},
    persoStatus: null,
    infoProfes: {},
    proStatus: null,
    infoConjoints: {},
    conjointsStatus: null,
    infoEnfants: {},
    enfantsStatus: null,
    infoDossier: {},
    dossierStatus: null,
    profileImage: null,
    profileImageStatus: null,
  },
  reducers: {
    resetUpdateData: (state, action) => {
      state.infoCompte = {};
      state.compteStatus = null;
      state.infoPerso = {};
      state.persoStatus = null;
      state.infoProfes = {};
      state.proStatus = null;
      state.infoConjoints = {};
      state.conjointsStatus = null;
      state.infoEnfants = {};
      state.enfantsStatus = null;
      state.infoDossier = {};
      state.dossierStatus = null;
      state.profileImage = null;
      state.profileImageStatus = null;
    },
  },
  extraReducers: {
    // ------------ getAdherentCompteUpdateAPI ----------------
    [getAdherentCompteUpdateAPI.pending]: (state, action) => {
      state.compteStatus = "pending";
    },
    [getAdherentCompteUpdateAPI.fulfilled]: (state, action) => {
      state.compteStatus = "fulfilled";
      state.infoCompte = action.payload;
    },
    [getAdherentCompteUpdateAPI.rejected]: (state, action) => {
      state.compteStatus = "rejected";
    },
    [saveCompteAPI.fulfilled]: (state, action) => {
      state.compteStatus = "fulfilled";
      state.infoCompte = action.payload;
      console.log("called inside update saveCompteAPI ", action.payload);
    },
    [checkPprAdherentAPI.fulfilled]: (state, action) => {
      // state.pprExist = action.payload;
      state.compteStatus = "fulfilled";
      state.infoCompte = action.payload;
      console.log("called inside update checkPprAdherentAPI ", action.payload);
    },

    // ------------ getAdherentPersoUpdateAPI ----------------
    [getAdherentPersoUpdateAPI.pending]: (state, action) => {
      state.persoStatus = "pending";
    },
    [getAdherentPersoUpdateAPI.fulfilled]: (state, action) => {
      state.persoStatus = "fulfilled";
      state.infoPerso = action.payload;
    },
    [getAdherentPersoUpdateAPI.rejected]: (state, action) => {
      state.persoStatus = "rejected";
    },
    [savePersoAPI.fulfilled]: (state, action) => {
      state.persoStatus = "fulfilled";
      state.infoPerso = action.payload;
    },

    // ------------ getAdherentProUpdateAPI ----------------
    [getAdherentProUpdateAPI.pending]: (state, action) => {
      state.proStatus = "pending";
    },
    [getAdherentProUpdateAPI.fulfilled]: (state, action) => {
      state.proStatus = "fulfilled";
      state.infoProfes = action.payload;
    },
    [getAdherentProUpdateAPI.rejected]: (state, action) => {
      state.proStatus = "rejected";
    },
    [saveProDataAPI.fulfilled]: (state, action) => {
      state.proStatus = "fulfilled";
      state.infoProfes = action.payload;
    },

    // ------------ getAdherentConjointsUpdateAPI ----------------
    [getAdherentConjointsUpdateAPI.pending]: (state, action) => {
      state.conjointsStatus = "pending";
    },
    [getAdherentConjointsUpdateAPI.fulfilled]: (state, action) => {
      state.conjointsStatus = "fulfilled";
      state.infoConjoints = action.payload;
    },
    [getAdherentConjointsUpdateAPI.rejected]: (state, action) => {
      state.conjointsStatus = "rejected";
    },

    // ------------ getAdherentEnfantsUpdateAPI ----------------
    [getAdherentEnfantsUpdateAPI.pending]: (state, action) => {
      state.enfantsStatus = "pending";
    },
    [getAdherentEnfantsUpdateAPI.fulfilled]: (state, action) => {
      state.enfantsStatus = "fulfilled";
      state.infoEnfants = action.payload;
    },
    [getAdherentEnfantsUpdateAPI.rejected]: (state, action) => {
      state.enfantsStatus = "rejected";
    },

    // ------------ getAdherentDossierUpdateAPI ----------------
    [getAdherentDossierUpdateAPI.pending]: (state, action) => {
      state.dossierStatus = "pending";
    },
    [getAdherentDossierUpdateAPI.fulfilled]: (state, action) => {
      state.dossierStatus = "fulfilled";
      state.infoDossier = action.payload;
    },
    [getAdherentDossierUpdateAPI.rejected]: (state, action) => {
      state.dossierStatus = "rejected";
    },

    // -------------- getProfileImageAPI --------------
    [getProfileImageAPI.pending]: (state, action) => {
      state.profileImageStatus = "pending";
      state.profileImage = null;
    },
    [getProfileImageAPI.fulfilled]: (state, action) => {
      state.profileImageStatus = "fulfilled";
      state.profileImage = action.payload;
      console.log('le profil image est ::: ',action.payload)
    },
    [getProfileImageAPI.rejected]: (state, action) => {
      state.profileImageStatus = "pending";
    },
  },
});

export const getProfileImageSelector = (state) =>
  state.adherentUpdate.profileImage;
export const getProfileImageStatusSelector = (state) =>
  state.adherentUpdate.profileImageStatus;

export const { resetUpdateData } = AdherentUpdateSlice.actions;

export default AdherentUpdateSlice.reducer;
