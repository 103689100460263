import { createAsyncThunk, createReducer, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";
import {getProfileImageAPI} from "./AdherentUpdateSlice";


export const getPeriodeAPI = createAsyncThunk(
  "params/getPeriode",
  async (id) => {
    const response = await clientAPI.get("/api/colonieParam/getPeriode/" + id);
    return response.data;
  }
);
export const getEnfantAPI = createAsyncThunk(
  "params/getListEnfant",
  async (cin) => {
    const response = await clientAPI.get("/api/colonieParam/getListEnfant/" + cin);
    console.log('liste enfant :: ',response.data)
    return response.data;
  }
);
export const addPeriodeAPI = createAsyncThunk(
  "params/addPeriode",
  async (data) => {
    const formData = new FormData();
    console.log('data is ::: ',data.coloniesId)
    formData.append("coloniesId", data.coloniesId);
    formData.append("nom", data.description);
    formData.append("nbrPlace", data.nombrePlace);
    formData.append("dateDebut", data.dateDebut);
    formData.append("dateFin", data.dateFin);
    const response = await clientAPI.post("/api/colonieParam/savePeriode", formData);
    return response.data;
  }
);
export const deletePeriodeAPI = createAsyncThunk(
  "params/deletePeriode",
  async (id) => {
    const response = await clientAPI.delete("/api/colonieParam/deletePeriode/" + id);
    return response.data;
  }
);
export const getColonieAPI = createAsyncThunk(
  "params/getColonie",
  async () => {
    const response = await clientAPI.get("/api/colonieParam/list");
    return response.data;
  }
);


export const getColonieImage = createAsyncThunk(
  "params/getColonieImage",
  async (idColonie) => {
    const response = await axios.get(
      SERVER_URL + "/api/colonieParam/image/" + idColonie,
      {
        headers: getAuthHeader(),
        responseType: "blob", // Assurez-vous que le type de réponse est JSON
      }
    );

    console.log('le uri de image :::: ',response.data)
    return response.data;
  }
);

export const addImageAPI = createAsyncThunk(
  "params/addColonieImages",
  async (data) => {

    const formData = new FormData();
      formData.append("id", data.id);
      formData.append("photo", data.photo);

      try {
        const response = await clientAPI.post("/api/colonieParam/addImage", formData,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Réponse du serveur:', response.data);
        return response.data;
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
      }

  }
);
export const addColonieAPI = createAsyncThunk(
  "params/addColonie",
  async (data) => {

    const formData = new FormData();
    if (data.id !== null && data.id !== undefined) {
      formData.append("id", data.id);
    }else{
      formData.append("id", 0);
    }
    formData.append("libelle", data.libelle);
    formData.append("annee", data.annee);
    if (data.photo!== null) {
      formData.append("photo", data.photo);
    }else
    try {
    const response = await clientAPI.post("/api/colonieParam/save", formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
      console.log('Réponse du serveur:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
    }

  }
);
export const deleteColonieAPI = createAsyncThunk(
  "params/deleteColonie",
  async (id) => {
    const response = await clientAPI.post("/api/colonieParam/delete/" + id);
    return response.data;
  }
);
export const getVilleAPI = createAsyncThunk("params/getVilles", async () => {
  const response = await clientAPI.get("/api/ville/list");
  return response.data;
});

export const addVilleAPI = createAsyncThunk(
  "params/addVilles",
  async (data) => {
    const response = await clientAPI.post("/api/ville/save", data);
    return response.data;
  }
);

export const deleteVilleAPI = createAsyncThunk(
  "params/deleteVilles",
  async (id) => {
    const response = await clientAPI.delete("/api/ville/" + id);
    return response.data;
  }
);

export const getImputationsAPI = createAsyncThunk(
  "params/getImputations",
  async () => {
    const response = await clientAPI.get("/api/imputation/list");
    return response.data;
  }
);

export const addImputationAPI = createAsyncThunk(
  "params/addImputation",
  async (data) => {
    const response = await clientAPI.post("/api/imputation/save", data);
    return response.data;
  }
);

export const deleteImputationAPI = createAsyncThunk(
  "params/deleteImputation",
  async (id) => {
    const response = await clientAPI.delete("/api/imputation/" + id);
    return response.data;
  }
);

export const getStatutsAPI = createAsyncThunk("params/getStatuts", async () => {
  const response = await clientAPI.get("/api/statut/list");
  return response.data;
});

export const addStatutAPI = createAsyncThunk(
  "params/addStatut",
  async (data) => {
    const response = await clientAPI.post("/api/statut/save", data);
    return response.data;
  }
);

export const deleteStatutAPI = createAsyncThunk(
  "params/deleteStatut",
  async (id) => {
    const response = await clientAPI.delete("/api/statut/" + id);
    return response.data;
  }
);

export const getPaysAPI = createAsyncThunk("params/getPays", async () => {
  const response = await clientAPI.get("/api/pays/list");
  return response.data;
});

export const getFonctionsAPI = createAsyncThunk(
  "params/getFonctions",
  async () => {
    const response = await clientAPI.get("/api/fonction/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_fonction,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addFonctionAPI = createAsyncThunk(
  "params/addFonction",
  async (data) => {
    const response = await clientAPI.post("/api/fonction/save", data);
    return response.data;
  }
);

export const deleteFonctionAPI = createAsyncThunk(
  "params/deleteFonction",
  async (id) => {
    const response = await clientAPI.delete("/api/fonction/" + id);
    return response.data;
  }
);

export const getGradesAPI = createAsyncThunk("params/getGrades", async () => {
  const response = await clientAPI.get("/api/grade/list");
  const res =
    response.data &&
    response.data.map((e) => ({
      id: e.id_grade,
      libelle: e.libelle,
      code: e.code,
      montant_cotisation: e.montant_cotisation,
    }));
  return res;
});

export const addGradeAPI = createAsyncThunk("params/addGrade", async (data) => {
  const response = await clientAPI.post("/api/grade/save", data);
  return response.data;
});

export const deleteGradeAPI = createAsyncThunk(
  "params/deleteGrade",
  async (id) => {
    const response = await clientAPI.delete("/api/grade/" + id);
    return response.data;
  }
);

export const getEchellesAPI = createAsyncThunk(
  "params/getEchelles",
  async () => {
    const response = await clientAPI.get("/api/echelle/list");
    return response.data;
  }
);

export const getAffectationsAPI = createAsyncThunk(
  "params/getAffectations",
  async () => {
    const response = await clientAPI.get("/api/affectation/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_affectation,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addAffectationAPI = createAsyncThunk(
  "params/addAffectation",
  async (data) => {
    const response = await clientAPI.post("/api/affectation/save", data);
    return response.data;
  }
);

export const deleteAffectationAPI = createAsyncThunk(
  "params/deleteAffectation",
  async (id) => {
    const response = await clientAPI.delete("/api/affectation/" + id);
    return response.data;
  }
);

export const getRegionsAPI = createAsyncThunk("params/getRegions", async () => {
  const response = await clientAPI.get("/api/region/list");
  const res =
    response.data &&
    response.data.map((e) => ({ id: e.id_region, libelle: e.region_fr }));
  console.log('im heeeer ::::::::::')
  return res;
});

export const getProvincesAPI = createAsyncThunk(
  "params/getProvinces",
  async (idRegion) => {
    const response = await clientAPI.get("/api/province/list/" + idRegion);
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_province,
        libelle: e.province_fr,
      }));
    return res;
  }
);

export const getCommunesAPI = createAsyncThunk(
  "params/getCommunes",
  async (idProvince) => {
    const response = await clientAPI.get("/api/commune/list/" + idProvince);
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_commune,
        libelle: e.commune_fr,
      }));
    return res;
  }
);

export const getTypeAdherentsAPI = createAsyncThunk(
  "params/getTypeAdherents",
  async () => {
    const response = await clientAPI.get("/api/type_adherent/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_type_adherent,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addTypeAdherentsAPI = createAsyncThunk(
  "params/addTypeAdherents",
  async (data) => {
    const response = await clientAPI.post("/api/type_adherent/save", data);
    return response.data;
  }
);

export const deleteTypeAdherentsAPI = createAsyncThunk(
  "params/deleteTypeAdherents",
  async (id) => {
    const response = await clientAPI.delete("/api/type_adherent/" + id);
    return response.data;
  }
);

export const getModesReglementsAPI = createAsyncThunk(
  "params/getModesReglements",
  async () => {
    const response = await clientAPI.get("/api/mode_reglement/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_mode_reglement,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const addModeReglementAPI = createAsyncThunk(
  "params/addModeReglement",
  async (data) => {
    const response = await clientAPI.post("/api/mode_reglement/save", data);
    return response.data;
  }
);

export const deleteModeReglementAPI = createAsyncThunk(
  "params/deleteModeReglement",
  async (id) => {
    const response = await clientAPI.delete("/api/mode_reglement/" + id);
    return response.data;
  }
);

export const getSituationsFamilialeAPI = createAsyncThunk(
  "params/getSituationsFamiliale",
  async () => {
    const response = await clientAPI.get("/api/situation_familiale/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_situation_familiale,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeFichierToLoadInCotisationAPI = createAsyncThunk(
  "params/getTypeFichierToLoadInCotisation",
  async () => {
    const response = await clientAPI.get("/api/type_cotisation/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.id_type_cotisation,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeOperationMoisAPI = createAsyncThunk(
  "params/getTypeOperationMois",
  async () => {
    const response = await clientAPI.get("/api/code_operation_mois/list");
    const res =
      response.data &&
      response.data.map((e) => ({
        id: e.idCode,
        libelle: e.libelle,
      }));
    return res;
  }
);

export const getTypeOrganismeAPI = createAsyncThunk(
  "params/getTypeOrganisme",
  async () => {
    const response = await clientAPI.get("/api/type_organisme/list");
    return response.data;
  }
);

export const getListTypeFichiersAPI = createAsyncThunk(
  "params/getListTypeFichier",
  async () => {
    const response = await clientAPI.get("/api/type_fichier/list/");
    return response.data;
  }
);

export const addTypeFichiersAPI = createAsyncThunk(
  "params/addTypeFichier",
  async (data) => {
    const response = await clientAPI.post("/api/type_fichier/save", data);
    return response.data;
  }
);

export const deleteTypeFichiersAPI = createAsyncThunk(
  "params/deleteTypeFichier",
  async (id) => {
    const response = await clientAPI.delete("/api/type_fichier/" + id);
    return response.data;
  }
);

export const getTypeAttestationAPI = createAsyncThunk(
  "params/getTypeAttestation",
  async () => {
    const response = await clientAPI.get("/api/type_attestation/list");
    return response.data;
  }
);

export const addTypeAttestationAPI = createAsyncThunk(
  "params/addTypeAttestation",
  async (data) => {
    const response = await clientAPI.post("/api/type_attestation/save", data);
    return response.data;
  }
);

export const deleteTypeAttestationAPI = createAsyncThunk(
  "params/deleteTypeAttestation",
  async (id) => {
    const response = await clientAPI.delete("/api/type_attestation/" + id);
    return response.data;
  }
);

const initialState = {
  sidebarShow: "responsive",
};
export const navRecuer = createReducer(initialState, (builder) => {
  builder.addCase("set", (state, action) => {
    return { ...state, ...action };
  });
});

const Params = createSlice({
  name: "params",
  initialState: {
    data: {},
    colonie: [],
    periode: [],
    imageSrc: null,
    status1: 'idle',
    enfant:[],
    imputations: [],
    status: [],
    pays: [],
    villes: [],
    fonctions: [],
    grades: [],
    echelles: [],
    affectations: [],
    regions: [],
    provinces: [],
    communes: [],
    typesAdherent: [],
    modesReglement: [],
    situationsFamiliale: [],
    typeCotisationFichiers: [],
    typeOperationMois: [],
    typeOrganisme: [],
    typeFichiers: [],
    typeAttestations: [],
    isActive: null,
  },
  reducers: {},

  extraReducers: {
    // -------------- getImageAPI --------------
    [getColonieImage.pending]: (state, action) => {
      state.status1 = "loading";
      state.imageSrc = [];
    },
    [getColonieImage.fulfilled]: (state, action) => {
      state.status1 = "succeeded";
      console.log('la liste de images  :: ',action.payload)
      state.imageSrc = URL.createObjectURL(action.payload );
      console.log('la liste de images 222 :: ',state.imageSrc)
    },
    [getColonieImage.rejected]: (state, action) => {
      state.status1 = "failed";
    },
    [getPeriodeAPI.fulfilled]: (state, action) => {
      state.periode = action.payload;
      console.log('la liste de periode :: ',state.periode)
    },

    [getColonieAPI.fulfilled]: (state, action) => {
      state.colonie = action.payload;
      console.log('la liste de colonie :: ',state.colonie)
    },
    [getEnfantAPI.fulfilled]: (state, action) => {
      state.enfant = action.payload;
      console.log('leste enfant est :: ',action.payload)
    },
    [getVilleAPI.fulfilled]: (state, action) => {
      state.villes = action.payload;
    },

    [addVilleAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
    },
    [deleteVilleAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat " + action.payload);
    },
    [getImputationsAPI.fulfilled]: (state, action) => {
      state.imputations = action.payload;
    },
    [getStatutsAPI.fulfilled]: (state, action) => {
      state.status = action.payload;
    },
    [getPaysAPI.fulfilled]: (state, action) => {
      // let filtered = action.payload.find((e) => e.libelle == "Maroc");
      // state.pays = [
      //   filtered,
      //   action.payload.filter((e) => e.libelle != "Maroc"),
      // ];
      state.pays = action.payload;
    },
    [getFonctionsAPI.fulfilled]: (state, action) => {
      state.fonctions = action.payload;
    },
    [getGradesAPI.fulfilled]: (state, action) => {
      state.grades = action.payload;
    },
    [getEchellesAPI.fulfilled]: (state, action) => {
      state.echelles = action.payload;
    },
    [getAffectationsAPI.fulfilled]: (state, action) => {
      state.affectations = action.payload;
    },
    [getRegionsAPI.fulfilled]: (state, action) => {
      state.regions = action.payload;
    },
    [getProvincesAPI.fulfilled]: (state, action) => {
      state.provinces = action.payload;
    },
    [getCommunesAPI.fulfilled]: (state, action) => {
      state.communes = action.payload;
    },
    [getTypeAdherentsAPI.fulfilled]: (state, action) => {
      state.typesAdherent = action.payload;
    },
    [getModesReglementsAPI.fulfilled]: (state, action) => {
      state.modesReglement = action.payload;
    },
    [getSituationsFamilialeAPI.fulfilled]: (state, action) => {
      state.situationsFamiliale = action.payload;
    },
    [getTypeFichierToLoadInCotisationAPI.fulfilled]: (state, action) => {
      state.typeCotisationFichiers = action.payload;
    },
    [getTypeOperationMoisAPI.fulfilled]: (state, action) => {
      state.typeOperationMois = action.payload;
    },
    [getTypeOrganismeAPI.fulfilled]: (state, action) => {
      state.typeOrganisme = action.payload;
    },
    [getListTypeFichiersAPI.fulfilled]: (state, action) => {
      state.typeFichiers = action.payload;
    },
    [addTypeFichiersAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteTypeFichiersAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [addTypeAdherentsAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteTypeAdherentsAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [addModeReglementAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("add etat ", action.payload);
    },
    [deleteModeReglementAPI.fulfilled]: (state, action) => {
      //state.villes.push(action.payload);
      console.log("delete etat ", action.payload);
    },
    [getTypeAttestationAPI.fulfilled]: (state, action) => {
      state.typeAttestations = action.payload;
    },
  },
});

export const getVilleNameById = (idVille) => (state) => {
  const ville = state.params.villes.find((e) => e.id == idVille);
  return ville ? ville.libelle : "----";
};

export const {} = Params.actions;

export default Params.reducer;
